.warning-icon {
  composes: text-32 from global;
}

.skip-button {
  flex: 1;
  min-width: unset !important;
}

.connect-button {
  flex: 5;
}

.button-container {
  composes: flex from global;
  justify-content: space-between;
}

.modal-container {
  composes: flex flex-column pb24 pt24 full-height from global;
  align-items: center;
  max-width: 384px;
  margin-left: auto;
  margin-right: auto;
}
