.step-header {
  composes: flex text-left mt24 from global;
  align-items: flex-start;
}

.edit-button {
  composes: relative ml8 from global;
}

.edit-icon {
  composes: icon-edit text-blue from global;
}

.otp-container {
  composes: mt24 from global;
  margin-left: auto;
  margin-right: auto;
}

.otp-error {
  composes: text-12 pt16 text-center from global;
  color: var(--color-danger);
}

.resend-button {
  composes: relative from global;
  padding-top: 32px !important;
  padding-bottom: 38px !important;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.edit-button {
  composes: relative from global;
  margin-left: 8px !important;
}
