.modal-component {
  /* important is needed to override Modal's br */
  border-radius: 8px !important;
}

.signup-modal-container {
  position: relative;
}

.content {
  text-align: left;
  width: 100vw;
  max-width: 408px;
  height: 100vh;
  max-height: 524px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: max-height 0.3s ease-in;
}

.content-expanded {
  max-height: 572px;
}

.btn-close {
  composes: icon-bg-hover absolute p4 from global;
  color: var(--text-color-normal);
  top: 24px;
  right: 24px;
  height: 24px;
  width: 24px;
  opacity: 0.7;
  /* Should be higher than feedback form's z-index(1) */
  z-index: 2;
}

.background {
  composes: absolute full-width from global;
  top: 0;
  left: 0;
}

.bg-wrapper {
  composes: absolute full-height full-width from global;
  top: 0;
  left: 0;
}

.feedback-form-container {
  composes: absolute full-width full-height from global;
  top: 0;
  left: 0;
  z-index: 1;
}

.feedback-form-container.hidden {
  z-index: -1;
}

@media (--screen-tabletOrLower) {
  .modal-component {
    border-radius: 12px 12px 0 0 !important;
  }
}
