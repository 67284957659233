.close-btn-container {
  composes: absolute from global;
  top: 16px;
  right: 0;
}

.close-btn {
  composes: icon-bg-hover text-18 text-light from global;
}

.copy-container {
  /* Negative margin because animation viewbox is not perfectly aligned with inner animation markup */
  margin-top: -16px;
}

.accordion-content {
  transition: all 0.3s ease-in-out;
}

.accordion-item {
  composes: mt16 from global;
}

/* Adds margin bottom to last accordion so that it is fully in view (since CTA Container is fixed.) */
.last-accordion-item {
  composes: mb64 from global;
}

.holdings-list-container {
  /* TODO: Tokenize this */
  background-color: #f9fafc;
  border: 0.5px solid var(--color-border);
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.holdings-list {
  composes: pl16 from global;
}

.cta-container {
  composes: absolute p16 full-width from global;
  box-shadow: 0 -0.5px 0 0 rgba(221, 224, 228, 1);
  background-color: var(--color-white);
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.animation-container {
  max-width: 200px;
  margin: 0 auto;
}

.animation {
  margin: 0 auto;
  width: 200px;
}

.non-eligible-mf-info {
  composes: text-center font-regular text-normal text-12 mt8 from global;
}
