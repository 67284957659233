.step-header {
  composes: flex text-left mt24 from global;
  align-items: flex-start;
}

@media (--screen-tabletOrLower) {
  .step-header {
    justify-content: space-between;
  }
}

.input-container {
  composes: mt24 from global;
}
/* Resets centered text style inserted by home.css, composition not supported here  */
.input-container label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-field {
  composes: ipt ipt-lg mt4 from global;
  flex-grow: 1;
  /*
       ipt-lg has width of 294px and modal's width is 380px, so width needs to be overridden
     */
  width: 100%;
}

.input-field::placeholder {
  color: var(--text-color-light);
}

.error-text {
  composes: text-12 mt4 from global;
  color: var(--text-color-light);
}

/* Adding negative margin to error message because phone number input has baked in bottom margin */
.phone-number-error {
  margin-top: -10px;
}

.acknowledgement-card-container {
  composes: pb24 pt24 from global;
}
