.container {
  composes: flex-column full-height from global;
  justify-content: flex-start;
}

.title {
  composes: font-medium mt16 mb4 text-dark text-24 lh-138 from global;
}

.sub-title-submitted,
.sub-title-completed {
  composes: text-14 lh-169 from global;
}

.step-title-completed {
  width: 85%;
}
.step-desc {
  composes: text-13 mb20 from global;
  font-weight: 400;
}

.document-container {
  composes: flex-start from global;
  padding: 30px 52px 0 0;
  align-items: start;
}

.document-container:first-child {
  padding-top: 0;
}

.document-meta-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.doc-title {
  composes: text-dark font-regular text-14 lh-157 mb4 from global;
}

.doc-description {
  composes: text-light inline-block text-12 lh-169 from global;
}

.doc-notes {
  composes: inline-block text-12 mt6 lh-169 from global;
  color: #bf7000;
}

.doc-image {
  composes: mr8 from global;
  width: 24px;
  height: 24px;
}

.document-footer {
  composes: full-width pt8 from global;
  display: flex;
  justify-content: space-around;
}

.continue-btn-big {
  composes: btn btn-lg btn-primary-blue mb8 full-width from global;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: unset;
  padding: 0;
}

.document-wrapper {
  composes: m32 flex-column from global;
  justify-content: space-between;
  flex-grow: 1;
}

.document-header-returning,
.document-header-submitted,
.document-header-completed {
  composes: full-width flex-column p24 from global;
  justify-content: center;
  height: auto;
  background-repeat: no-repeat;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
}

.document-header-submitted {
  background-image: url('../../../../assets/svg/signup/broker-select-header.svg');
  background-repeat: no-repeat;
  background-position: 100% 20px;
}

.document-header-completed {
  background-image: url('../../../../assets/svg/signup/final-header-completed.svg');
  background-position: 110% 150%;
}

/* custom scroll for broker chooser (for browsers other than FireFox */
.document-wrapper::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.document-wrapper::-webkit-scrollbar-button {
  width: 4px;
  height: 4px;
}

.document-wrapper::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0;
  border-radius: 0;
}

.document-wrapper::-webkit-scrollbar-corner {
  background: transparent;
}

.document-wrapper::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border: 0;
  border-radius: 2px;
}

.document-wrapper::-webkit-scrollbar-thumb:hover,
.document-wrapper::-webkit-scrollbar-thumb:active {
  background: #d8d8d8;
}

.document-wrapper::-webkit-scrollbar-track:hover,
.document-wrapper::-webkit-scrollbar-track:active {
  background: #ffffff;
}

@media (--screen-smaller) {
  .document-container {
    padding-right: 30px;
  }
}
