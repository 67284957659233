.acknowledgement-card-container {
  composes: flex flex-column pb24 pt24 full-height from global;
  justify-content: space-between;
}

.acknowledgement-card {
  flex: 1;
}

.redirect-timer {
  color: var(--color-green);
}
