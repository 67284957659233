.subscriber-container {
  composes: flex pt8 from global;
  align-items: center;
}

.input-container {
  composes: mr8 flex br-4 from global;
  padding: 1px 0;
  width: 216px;
  background-color: var(--color-white);
  border: 1px solid var(--color-border);

  &:has(.input-item:focus-visible) {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-blue);
    transition: 0.3s;
  }
}

.email-input-icon-container {
  composes: flex from global;
  align-items: center;
  margin: 10px;
}

.input-item {
  composes: full-width from global;
  border: none;
  background-color: transparent;
}

.input-item::placeholder {
  color: var(--text-color-light);
}

.loading-btn {
  padding: 9px 13px;
  background-color: #9197a1;
  border: unset;
}

.loading-btn:hover {
  background-color: #9197a1;
}

.loading-icon {
  composes: flex text-16 from global;
  justify-content: center;
  animation: loading-animation 200ms ease-in-out 0ms infinite;
  animation-name: loading-animation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes loading-animation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(-360deg);
  }
}
