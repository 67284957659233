.container {
  composes: full-width flex-column from global;
}

.step {
  composes: flex-column full-width relative pl20 pb32 from global;
  animation-name: slideUp;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
}

.step-border {
  composes: absolute full-width from global;
  top: 0;
  left: -2px;
  height: 0;
  border-left: 3px solid #d4d6db;
  transition: height 0.3s ease-in;
}

.step:last-of-type {
  padding-bottom: 0;
}

.step:last-of-type .step-border {
  border: transparent;
}

.step-border.last-step {
  border: transparent;
}

.step.completed {
  color: var(--text-color-light);
}

.step-border.completed {
  border-color: var(--low-risk-bg);
}

.step.completed:last-of-type {
  margin-left: 2px;
}

.step-border.last-completed {
  border-color: #d4d6db;
}

.step-title {
  composes: text-16 lh-138 font-medium from global;
}

.step-description {
  composes: text-14 lh-144 font-regular text-light from global;
}

.step-icon,
.step-icon-completed,
.step-icon-active {
  composes: text-20 absolute from global;
  top: 0;
  left: -11px;
  background-color: var(--color-white);
}

.step-icon {
  composes: icon-radio from global;
}

.step-icon-completed {
  composes: icon-check_filled-circle from global;
}

.step-icon-completed::before {
  color: var(--color-success);
}

.step-icon-active {
  composes: icon-radio from global;
}

.step-icon-active::before {
  color: var(--color-border);
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
