.container {
  composes: flex flex-column full-width from global;
}

.btn-style {
  composes: reset-button-style br-4 full-width flex-apart p12 from global;
  border: 1px solid var(--color-border);
}

.radio {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 2px solid var(--color-border);
}

.radio-selected {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 8px solid var(--color-blue);
}

.logo {
  width: 24px;
  height: 24px;
}

.inner-container {
  composes: flex from global;
  align-items: center;
}
