.container {
  composes: absolute full-width full-height flex from global;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.content {
  composes: p24 text-center flex from global;
  background-color: var(--color-white);
  width: 80%;
  height: 60%;
  animation-name: slideUp;
  animation-duration: 0.7s;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
}

.icon {
  font-size: 45px;
}

.title {
  composes: text-16 lh-144 from global;
  font-weight: 600;
}

.text-content {
  composes: mt16 lh-138 text-14 from global;
  color: var(--text-color-light);
}
