.form-fields-wrapper {
  composes: flex-column from global;
  align-items: flex-start;
}
.form-field-wrapper {
  composes: full-width from global;
  margin-left: unset;
}
.title {
  composes: font-medium text-dark from global;
  line-height: 1.29;
  font-size: 23px;
}
.signup-subtitle {
  composes: text-23 lh-138 mt8 mb24 text-dark from global;
}
.brokerage-account-box {
  composes: br-4 lh-138 mt40 row from global;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
  background-repeat: no-repeat;
}

.brokerage-account-box-sc {
  background-image: url('../../../../assets/svg/signup/first-screen-sc.svg');
  background-position: 105% 45%;
  background-size: 60%;
  background-repeat: no-repeat;
}

.brokerage-account-box-partner {
  background-image: url('../../../../assets/svg/signup/first-screen-partners.svg');
  background-size: 50%;
  background-position: right 55%;
}

.brokerage-account-title {
  composes: ml16 text-dark from global;
}

.brokerage-account-title-sc {
  composes: text-14 lh-169 from global;
  width: 80%;
}

.brokerage-account-title-partners {
  composes: text-16 lh-138 from global;
  width: 60%;
}

.brokerage-account-info {
  composes: mt4 ml16 text-13 lh-138 from global;
  width: 65%;
}
.form-label {
  composes: lh-169 font-medium text-14 inline-block from global;
  color: var(--text-color-normal);
}
.edit-btn {
  composes: link ml4 from global;
  font-weight: 600;
}
.icon-edit {
  composes: icon-edit text-blue pointer from global;
}

.form-input {
  composes: full-width ipt text-16 from global;
  padding: 12px 16px;
  letter-spacing: 0.4px;
  height: 48px;
  -webkit-appearance: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.form-input:autofill,
.form-input:autofill:hover,
.form-input:autofill:focus,
.form-input:autofill:active,
.form-input:-webkit-autofill,
.form-input:-webkit-autofill:hover,
.form-input:-webkit-autofill:focus,
.form-input:-webkit-autofill:active {
  box-shadow: 0 4 10 0px var(--color-black) inset;
  -webkit-box-shadow: 0 4 10 0px var(--color-black) inset;
}

.form-input:disabled {
  box-shadow: 0 4 10 0px #f4f4f5 inset !important;
  -webkit-box-shadow: 0 4 10 0px #f4f4f5 inset !important;
}

.form-input::placeholder {
  color: var(--text-color-light);
}
.input-error {
  border: 1px solid rgba(255, 89, 66, 0.6);
}
.whatsapp_checkbox {
  padding-left: -8px !important;
}
.verified-animation {
  composes: absolute from global;
  top: 25%;
  right: 10px;
  width: 26px;
  height: 26px;
  border-radius: 10px;
}
.input-error-container {
  composes: text-12 font-regular lh-138 mb4 from global;
  color: var(--color-danger);
  height: 12px;
}
.signup-page-btn-big {
  composes: btn btn-lg font-regular btn-primary-blue mt16 full-width from global;
  display: flex;
  min-height: 48px;
  justify-content: center;
  align-items: center;
  max-width: unset;
  padding: 0;
}
.signup-page-btn-big:disabled {
  /*
      Default button disabled background is set to rgba(31,122,224, 0.45)
      Because of the alpha part set to 0.45, anything animating even if going below the button seems like going up
    */
  background-color: #a0c3f2;
}

.card-shimmer {
  composes: shine ml16 mt8 from global;
  height: 20px;
  width: 65%;
}

@media (--screen-mobile), (--screen-smaller) {
  .title {
    font-size: 18px;
  }
  .brokerage-account-info {
    width: 70%;
  }
  .brokerage-account-box {
    padding: 0;
  }
}
@media (--screen-tabletOrLower) {
  .form-field-wrapper {
    flex-basis: 50%;
    margin-left: 0;
    margin-right: 0;
  }
  .brokerage-account-info {
    width: 70%;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
