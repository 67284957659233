.shimmer-heading {
  composes: br-4 shine mb16 from global;
  width: 213px;
  height: 24px;
}

.shimmer-fund-btn {
  composes: shine btn-lg full-width br-4 from global;
  max-width: 100%;
}
