.accordion-container {
  composes: mt12 from global;
  margin-left: -8px;
  margin-right: -20px;
  padding-right: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  animation-name: slideUp;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;

  /* FireFox support for scrollbar */
  scrollbar-color: #d8d8d8;
  scrollbar-width: thin;
}

/* custom scroll for broker chooser (for browsers other than FireFox */
.accordion-container::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.accordion-container::-webkit-scrollbar-button {
  width: 4px;
  height: 4px;
}

.accordion-container::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0;
  border-radius: 0;
}

.accordion-container::-webkit-scrollbar-corner {
  background: transparent;
}

.accordion-container::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border: 0;
  border-radius: 2px;
}

.accordion-container::-webkit-scrollbar-thumb:hover,
.accordion-container::-webkit-scrollbar-thumb:active {
  background: #d8d8d8;
}

.accordion-container::-webkit-scrollbar-track:hover,
.accordion-container::-webkit-scrollbar-track:active {
  background: #ffffff;
}

/* header */
.broker-header {
  display: flex;
}

/* select sidebar */
.broker-select-wrapper {
  flex: 0 0 36px;
}

.broker-select {
  composes: pointer p16 m0 from global;
  text-align: initial;
  border: 1px solid var(--color-border);
  border-radius: 12px;
}

.broker-collapse-container {
  composes: mb12 flex-center from global;
}

.popular-tag {
  composes: text-12 font-medium flex-center ml8 lh-138 from global;
  color: var(--color-horizon-sc);
  background-color: #eefcf8;
  border-radius: 2px;
  padding: 1px 4px;
}

/* broker info */
.broker-info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.broker-row {
  composes: flex mb12 from global;
  align-items: start;
}

.broker-logo {
  composes: mr12 p4 br-8 from global;
  height: 56px;
  width: 56px;
  border: 1px solid var(--color-border);
}

.broker-name {
  composes: flex text-16 mb2 font-medium lh-138 from global;
  color: var(--text-color-dark);
  transition: all 0.3s ease-in-out;
  align-items: center;
}

.broker-details {
  composes: flex from global;
  align-items: center;
  justify-content: space-around;
}

.broker-details-column {
  composes: flex-column from global;
  margin-right: auto;
}

.broker-details-column.acc-fee {
  min-width: 90px;
}

.broker-details-column.order-charges {
  min-width: 82px;
}

.broker-details-column.active-users {
  min-width: 74px;
}

.broker-desc-title {
  composes: text-12 font-regular mb4 lh-138 from global;
  color: var(--text-color-light);
}
.broker-desc-count {
  composes: text-16 font-medium lh-138 from global;
  color: var(--text-color-normal);
}
.broker-desc {
  composes: text-12 font-regular lh-138 from global;
  color: var(--text-color-light);
}

/* broker details */
.features-container {
  margin-left: 36px;
}

.broker-feature {
  display: flex;
  align-items: stretch;
  padding-top: 16px;
}

.feature-logo {
  width: 22px;
  height: 22px;
}

.feature-text {
  composes: text-13 lh-138 from global;
  padding-left: 12px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  color: var(--text-color-normal);
}

.read-more {
  composes: relative text-14 from global;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  color: var(--color-blue);
  padding: 0;
}

.external-icon {
  composes: icon-chevron-down text-16 ml4 from global;
  padding-top: 2px;
}

.signup-page-btn-big {
  composes: font-regular from global;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}
.link-btn {
  composes: flex-center link full-width mt12 mb12 pt16 pb16 from global;
  min-height: 52px;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
}
.cta-text {
  composes: mr8 font-medium text-14 line-height-one from global;
}
.broker-pill {
  composes: p4 from global;
  width: 24px;
  height: 24px;
  border: 0.75px solid var(--color-border);
  background-color: var(--color-white);
  border-radius: 20px;
  margin-left: -5px;
}

.broker-pill:first-of-type {
  margin-left: 0;
}

.popular-icon {
  composes: icon-order-toppedup text-16 mr8 from global;
  padding-top: 2px;
}

.popular-icon:before {
  color: #218c70;
}

@media (--screen-desktop) {
  .broker-select:hover .broker-name {
    color: var(--color-blue);
  }
  .broker-select:hover {
    border-color: var(--color-blue-500);
  }
}

@media (--screen-tabletOrLower) {
  .broker-select:active .broker-name {
    color: var(--color-blue);
  }
  .broker-select:active {
    border-color: var(--color-blue-500);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translate3d(0, 32px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}
