.signup-button {
  composes: reset-button-style from global;
  font-family: inherit;
}

.brand-container {
  composes: flex from global;
  align-items: center;
}

.partner-logo {
  composes: mr8 from global;
  height: 32px;
}

.icon-chevron {
  composes: icon-chevron-right mr8 text-14 mt4 from global;
  color: var(--text-color-light);
}

.smallcase-text {
  color: var(--color-blue);
}
