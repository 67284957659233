.title-container {
  composes: flex-column from global;
}

.title-container.classic {
  align-items: flex-end;
}

.title-container.mutant {
  align-items: flex-start;
}

.title {
  composes: text-20 font-medium text-dark mt12 full-width from global;
  line-height: 1.29;
}

.sub-title {
  composes: text-16 mt8 text-dark from global;
  line-height: 1;
  margin-bottom: 18px;
}

.card-shimmer {
  composes: shine mt12 full-width from global;
  height: 20px;
}

@media (--screen-tabletOrLower) {
  .sub-title {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

@media (--screen-mobile), (--screen-smaller) {
  .title {
    font-size: 18px;
  }

  .title-img {
    width: 100px;
  }

  .sub-title {
    font-size: 14px;
  }
}
