.app-link {
  composes: flex pl24 from global;
  flex-direction: column;
}

.get-app-text {
  composes: text-13 mb16 lh-169 from global;
}

.google-badge {
  width: 107.43px;
  height: 32px;
}

.apple-badge {
  width: 95.73px;
  height: 32px;
}

@media (max-width: 991px) {
  .app-link {
    flex: 0 0 50%;
    padding-left: 0;
    margin-top: 24px;
  }
}

@media (max-width: 575px) {
  .app-link {
    margin-top: 24px;
    flex: 0 0 100%;
  }
}
