.image-container {
  composes: flex mt24 from global;
  justify-content: space-between;
  align-items: flex-start;
}

.arrow-left {
  composes: arrow text-24 from global;
  color: var(--text-color-light);
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  outline: revert;
  height: 24px;
  width: 24px;
}
