.container {
  composes: flex pt24 pb24 pl16 pr16 from global;
  flex-direction: column;
  background-color: var(--banner-bg);
}

.title {
  composes: text-16 font-medium mb12 from global;
  color: var(--text-color-dark);
}

.subtitle {
  composes: text-14 lh-144 mb24 mb12 from global;
}

.button_container {
  composes: flex from global;
}

.login-button {
  composes: reset-button-styles btn btn-sm btn-primary-blue from global;
}

.signup-button {
  composes: ml12 reset-button-styles btn btn-sm btn-secondary-blue from global;
}
