.menu-btn {
  composes: reset-button-style full-width text-left from global;
  font-family: inherit;
}

.phone-number-container {
  composes: inline-block ellipsis from global;
  max-width: 165px;
}

.email-container {
  composes: ellipsis mt8 from global;
  max-width: 165px;
}

.verify-phone-btn-container {
  composes: p0 mb0 font-normal text-12 full-width flex from global;
  justify-content: space-between;
  align-items: center;
  margin-top: -6px;
  /* --color-gold-light with 24% opacity */
  background-color: rgba(253, 206, 88, 0.24);
}

.verify-phone-btn {
  composes: full-width flex pt8 pb8 from global;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  align-items: center;
}

.divider {
  margin-top: -8px;
}
