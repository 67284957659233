.tt-pro-container {
  composes: mt24 full-width reset-button-style pointer from global;

  @media (--screen-desktop) {
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 8px;
    width: unset;
  }
}

.tt-pro-container:hover .heading,
.tt-pro-container:hover .sub-heading {
  color: var(--color-blue);
}

.heading {
  composes: mr4 text-dark from global;
}
.sub-heading {
  composes: mr4 text-12 text-normal from global;
}
.header {
  composes: flex mb12 items-center from global;
}
.title {
  composes: flex from global;
  align-items: baseline;
}
.external-link-icon {
  @media (--screen-desktop) {
    margin-top: 2px;
  }
}
.offer-code {
  composes: pt8 pb8 mt16 text-11 text-normal from global;
  border-top: 1px dashed var(--color-accent-100);
  border-bottom: 1px dashed var(--color-accent-100);
}

.top-divider {
  composes: mt8 from global;
  border-top: 1px solid var(--color-border);
  @media (--screen-mobile) {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.pointer-icon {
  flex-grow: 0;
  flex-shrink: 0;
}

.sub-title-mobile {
  composes: mr4 text-13 text-normal text-left from global;
  margin-top: 2px;
}

.usp {
  margin-left: 52px;
  @media (--screen-desktop) {
    margin-left: 0px;
  }
}
