.signup-form {
  composes: ml24 mr24 mb24 from global;
}
.slide-up {
  animation-name: slideUp;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
}
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translate3d(0, 32px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}
.form-fields-wrapper {
  composes: flex-column from global;
  align-items: flex-start;
}
.form-field-wrapper {
  composes: full-width from global;
  margin-left: unset;
}
.hidden {
  visibility: hidden;
}
.form-label {
  composes: lh-169 inline-block font-medium text-14 from global;
  color: var(--text-color-normal);
}
.edit-btn {
  composes: link ml4 from global;
  font-weight: 600;
}
.icon-edit {
  composes: icon-edit text-blue pointer from global;
}
.form-input {
  composes: ipt text-16 full-width from global;
  padding: 18px 16px;
  letter-spacing: 0.4px;
  height: 52px;
}
.input-name {
  text-transform: capitalize;
}

.form-input:autofill,
.form-input:autofill:hover,
.form-input:autofill:focus,
.form-input:autofill:active,
.form-input:-webkit-autofill,
.form-input:-webkit-autofill:hover,
.form-input:-webkit-autofill:focus,
.form-input:-webkit-autofill:active {
  box-shadow: 0 0 0 32px var(--color-white) inset;
  -webkit-box-shadow: 0 0 0 32px var(--color-white) inset;
}

.form-input:disabled {
  box-shadow: 0 0 0 32px #f4f4f5 inset !important;
  -webkit-box-shadow: 0 0 0 32px #f4f4f5 inset !important;
}

.form-input::placeholder {
  color: var(--text-color-light);
}
.input-error {
  border: 1px solid rgba(255, 89, 66, 0.6);
}
.verified-animation {
  composes: absolute from global;
  top: 25%;
  right: 10px;
  width: 26px;
  height: 26px;
  border-radius: 10px;
}
.input-error-container {
  composes: text-12 mb20 lh-138 from global;
  color: var(--color-danger);
  height: 12px;
}
.signup-page-btn-big {
  composes: btn btn-lg font-regular btn-primary-blue mt8 mb8 from global;
  min-height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: unset;
  padding: 0;
}
.signup-page-btn-big:disabled {
  /*
      Default button disabled background is set to rgba(31,122,224, 0.45)
      Because of the alpha part set to 0.45, anything animating even if going below the button seems like going up
    */
  background-color: #a0c3f2;
}

.broker-logo {
  height: 18px;
  width: 18px;
}

.disclaimer-subtext {
  composes: text-12 text-left mt8 mb8 lh-144 from global;
  color: var(--text-color-light);
}

@media (--screen-tabletOrLower) {
  .form-field-wrapper {
    flex-basis: 50%;
    margin-left: 0;
    margin-right: 0;
  }
}
