.accordion-header-container {
  composes: br-8 full-width from global;
  border: 0.5px solid var(--color-border);
}

.accordion-info-header {
  composes: pt8 pb8 text-center from global;
  border-bottom: 0.5px solid var(--color-border);
  cursor: default;
}

.accordion-info-text {
  composes: text-light font-regular text-10 from global;
}

.no-bottom-border {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.broker-logo-container {
  composes: absolute flex-center from global;
  width: 16px;
  height: 16px;
  background-color: var(--color-white);
  border: 0.5px solid var(--color-border);
  border-radius: 50%;
  right: -25%;
  bottom: 15%;
}

.broker-logo {
  width: 8px;
  height: 8px;
}

.subtitle-copy {
  composes: mt4 text-13 font-regular from global;
  color: var(--text-color-normal);
}

.chevron-container {
  composes: flex-center from global;
  background-color: rgba(var(--text-color-light-rgb), 0.1);

  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.chevron-close {
  transform: rotate(0deg);
}

.chevron {
  color: var(--text-color-light);
  transition: transform 200ms linear;
}

.chevron-open {
  transform: rotate(180deg);
}
