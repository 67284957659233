.image-container {
  composes: flex mt24 from global;
  justify-content: space-between;
  align-items: flex-start;
}

.illustration {
  display: block;
}

@media (--screen-desktop), (--screen-tablet) {
  .illustration {
    width: 100px;
  }
}

@media (--screen-mobile) {
  .illustration {
    width: 100px;
  }
}
