.modal-container {
  composes: flex-column p24 from global;
  text-align: left;
  width: 100vw;
  max-width: 408px;
  min-height: 550px;
  height: 74vh;
  max-height: 700px;
}

.modal-content {
  composes: flex-column from global;
  justify-content: space-between;
  height: 100%;
}

.modal-section {
  composes: flex-column pb24 pt24 from global;
  text-align: left;
  align-items: center;
}

.modal-footer-section {
  composes: flex-column from global;
  text-align: left;
  align-items: center;
}

.modal-image-section {
  composes: flex-column p24 mb32 from global;
  align-items: center;
  padding-bottom: 0;
  background-image: url('../../assets/pngs/sc-image-bg.png');
  background-position: top;
}

.error-icon {
  composes: mb24 mt48 icon-order-error from global;
  font-size: 40px;
}

.modal-image {
  composes: br-4 p8 from global;
  background: var(--color-white);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.0527061),
    0 0 2px rgba(0, 0, 0, 0.200096);
}

.modal-icon {
  composes: text-32 from global;
  margin-top: -11px;
}

.icon-error,
.icon-subs-cancelled {
  color: var(--color-danger);
}

.icon-notification {
  color: var(--color-blue);
}

.status-card-wrapper {
  composes: mb24 from global;
  padding: 0;
}

.suggested-action-card {
  composes: pl16 br-4 from global;
  border: 1px solid var(--color-border);
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(47, 54, 63, 0.08);
}

.suggested-action-title {
  composes: font-medium block text-14 mb4 from global;
}

.suggested-action-cta {
  composes: inline-block font-semibold text-14 link from global;
}

.msg-container {
  flex-direction: column;
}

.content-title {
  composes: text-20 line-height-one font-medium text-center from global;
}

.content-description {
  composes: mt12 text-14 lh-169 text-center text-light from global;
}

.modal-footer {
  composes: flex from global;
  justify-content: space-between;
  width: 100%;
}

.order-btn {
  composes: width-100 mr16 from global;
}

.btn-text {
  composes: text-14 p4 inline-block from global;
}

@media (--screen-tabletOrLower) {
  .modal-container {
    padding: 0 0 72px 0;
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
  }

  .modal-section {
    padding: 24px;
  }

  .status-card-wrapper {
    padding: 24px 16px;
    width: 100%;
    background-color: var(--banner-bg);
    margin-bottom: 0;
  }

  .suggested-action-card {
    margin: 0;
    border: none;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.0527061),
      0 0 2px rgba(0, 0, 0, 0.200096);
  }

  .modal-footer {
    padding: 16px;
    border: 1px solid var(--color-border);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
    position: fixed;
    bottom: 0;
  }

  .with-suggested-action {
    box-shadow: none;
    border: none;
  }

  .btn-text {
    font-size: 14px;
    padding: 0;
  }
}
