.back-button {
  composes: reset-button-style mr12 from global;
  font-family: inherit;
}

.back-icon {
  composes: text-24 from global;
}

.back-icon::before {
  color: var(--color-blue);
}
