.errorCardContainer {
  composes: flex-center from global;
  height: 70%;
}

.otpWrapper {
  composes: full-width from global;
  /** Makes the cells smaller on mobile devices (6 cells cause overflow with current values)*/
  & input {
    width: 46px;
    height: 60px;
    padding: 0;
  }

  & div {
    padding-left: 6.5px;
    padding-right: 6.5px;
  }

  & div:first-child {
    padding-left: 0;
  }

  & div:last-child {
    padding-right: 0;
  }

  @media (--screen-mobile) {
    & div {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}
