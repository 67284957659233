.card {
  composes: relative full-width text-left br-8 from global;
  height: 180px;
  border: 1px solid var(--color-border);
}

.card-content {
  composes: relative full-height p16 text-left grid from global;
  align-content: space-between;
}

.shimmer-title {
  composes: shine br-4 mb8 from global;
  width: 42px;
  height: 16px;
}

.shimmer-value {
  composes: shine br-4 from global;
  width: 148px;
  height: 16px;
}

.shimmer-logo {
  composes: shine from global;
  border-radius: 64px;
  width: 80px;
  height: 24px;
  align-self: flex-end;
}
