.holdings-list-item {
  composes: flex pb16 pt16 from global;
  border-bottom: 1px solid var(--color-border);
  align-items: center;
}

.holdings-list-item-disabled {
  opacity: 0.6;
}

.holdings-list-item:last-child {
  border-bottom: none;
}

.holdings-logo-container {
  composes: flex-center mr12 from global;
  width: 20px;
  height: 20px;
  background-color: var(--color-white);
  border: 0.5px solid var(--color-border);
  border-radius: 2px;
}

.holdings-logo {
  composes: block from global;
  width: 15px;
  height: 15px;
}

.more-items-icon-container {
  composes: flex-center mr12 from global;
  width: 20px;
  height: 20px;
}

.eligible-tick {
  composes: mr16 from global;
  width: 20px;
  height: 20px;
  margin-left: auto;
}
