.verification-screen {
  composes: full-width full-height from global;
  animation-name: slideLeft;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
}

.header {
  composes: pt16 from global;
}

.input-container {
  composes: mb24 from global;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.heading {
  composes: text-24 pb8 from global;
}

.title-img {
  composes: mb12 absolute from global;
  top: 32px;
  left: 24px;
}

.header-text {
  composes: text-14 lh-169 from global;
  color: var(--text-color-normal);
}

.btn-close {
  composes: pointer absolute from global;
  top: 8px;
  right: 12px;
  height: 20px;
  width: 20px;
  opacity: 0.7;
  z-index: 1;
}

.phone-text {
  font-weight: 600;
}

.content {
  composes: p24 full-width full-height flex-column from global;
  text-align: initial;
  justify-content: center;
  background-image: url('../../../../assets/svg/otp-bottomsheet-header.svg');
  background-position: right top;
  background-repeat: no-repeat;
  border-radius: 8px 8px 0 0;
}

.icon-edit {
  composes: icon-edit text-blue pointer from global;
}

.footer-text {
  color: var(--text-color-light);
}

.footer-text-highlight {
  color: var(--text-color-light);
  font-weight: 600;
}

.footer-action {
  composes: font-medium from global;
}

.otp-error {
  composes: text-12 lh-138 from global;
  color: var(--color-danger);
}

.verified-text {
  composes: text-12 from global;
  color: var(--color-success);
  font-weight: 600;
}

.icon-verified {
  composes: icon-checkmark from global;
  font-weight: 600;
}

.icon-verified::before {
  color: var(--color-success);
}

@media (--screen-tabletOrLower) {
  .content {
    border-radius: 12 12px 0 0;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(30%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
