.emandate-card {
  composes: flex mb24 p12 br-4 from global;
  background-color: var(--color-white);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.09), 0 0 1px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid var(--color-border);
}

.modal-content {
  composes: p24 text-left from global;
  width: 375px;
}

.modal-bullets {
  composes: flex mb12 from global;
  align-items: center;
}

.modal-footer {
  composes: full-width align-center from global;
}

.order-cta-primary {
  composes: full-width mt12 btn btn-lg btn-primary-blue from global;
}

@media (--screen-tabletOrLower) {
  .emandate-card {
    margin-right: 8px;
    margin-left: 8px;
  }
}
