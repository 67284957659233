.container {
  height: 100%;
}
.header {
  composes: font-medium text-24 pb20 lh-138 text-dark from global;
  background-image: url('../../../../assets/svg/signup/broker-select-header.svg');
  background-repeat: no-repeat;
  background-position: right top;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4), 0 2px 7px rgba(0, 0, 0, 0.06);
}
.header-help {
  composes: font-medium text-16 pl24 flex text-dark full-width from global;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background-color: var(--background-grey);
  border-radius: 8px 8px 0 0;
}

.back-icon {
  composes: icon-chevron-left text-18 mr4 lh-138 from global;
  margin-left: -6px;
}

.back-btn {
  composes: text-14 pointer font-medium m0 ml24 mt24 p0 from global;
  color: var(--text-color-dark);
}

.back-btn:hover {
  color: var(--color-blue);
}

.help-text-container {
  composes: pb16 from global;
  overflow-y: scroll;
  background-color: var(--color-white);
}
.btn-close {
  cursor: pointer;
  color: var(--text-color-normal);
}

.qna-help {
  composes: flex-column pl24 pr24 mt12 text-14 from global;
  justify-content: space-between;
}

.footer-help {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  background-color: var(--color-white);
}

.form-fields-wrapper {
  composes: flex pl24 pr24 from global;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  /* this is required for scrolling purpose */
  height: 1px;
  flex: 1 1 auto;
}

.signup-page-btn-big {
  composes: font-regular from global;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0 0 1px 1px;
  z-index: 1;
  min-width: calc(100% - 48px);
}
.btn-primary {
  composes: btn btn-lg btn-primary-blue full-width m24 from global;
  min-height: 48px;
  padding: 0;
}

.mt-0 {
  margin-top: 0;
}
.overlay {
  composes: full-height full-width z-order-one absolute from global;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  animation: showHelpOverlay 0.2s ease-in;
  border-radius: 8px 8px 0 0;
}
.helpArticle {
  composes: flex absolute full-width z-order-two from global;
  animation: showHelp 0.5s ease-in-out;
  flex-direction: column;
  justify-content: end;
  border-radius: 8px 8px 0 0;
  background-color: var(--color-white);
  height: auto;
  max-height: 424px;
  overflow: hidden;
  bottom: 0;
  left: 0;
  font-size: 14px;
}
.hideOverlay {
  animation: hideHelpOverlay 0.2s ease-in;
}
.hideHelpArticle {
  animation: hideHelp 0.5s ease-in;
}

@media (--screen-tabletOrLower) {
  .btn-primary {
    width: calc(100% - 48px);
  }
  .header-help,
  .helpArticle,
  .overlay {
    border-radius: 12px 12px 0 0;
  }
  .back-btn:active {
    color: var(--color-blue);
  }
  .back-btn:hover {
    color: var(--text-color-dark);
  }
}

@keyframes showHelpOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.6;
  }
}
@keyframes hideHelpOverlay {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 0;
  }
}
@keyframes showHelp {
  from {
    bottom: -424px;
  }
  to {
    bottom: 0;
  }
}
@keyframes hideHelp {
  from {
    bottom: 0;
  }
  to {
    bottom: -424px;
  }
}
.slide-left {
  animation-name: slideLeft;
  animation-duration: 0.4s;
}
@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
