.header-container {
  composes: relative p24 pb16 flex from global;
  flex-direction: column;
  align-items: flex-start;
  background-image: url('../../../../../assets/svg/signup/user-details-header.svg');
  background-repeat: no-repeat;
  background-position: 100% -5%;
}

.title-large {
  composes: font-medium text-dark from global;
  line-height: 1.29;
  font-size: 23px;
}

.title-small {
  composes: text-14 font-medium text-dark from global;
}

.title-img {
  width: 135px;
}

.title-container {
  composes: flex full-width mt12 from global;
  justify-content: space-between;
}

.sub-title {
  composes: text-16 mt16 text-dark from global;
  line-height: 1;
  margin-bottom: 18px;
}

.back-btn {
  composes: text-14 font-medium pointer p0 m0 from global;
  color: var(--text-color-dark);
}

.back-icon {
  composes: icon-chevron-left text-18 mr4 lh-138 from global;
  margin-left: -6px;
}

.back-btn:hover {
  color: var(--color-blue);
}

.display-name {
  composes: mr8 ellipsis text-16 flex from global;
  align-items: center;
  max-width: 100%;
}

.signup-subtitle-partner {
  composes: lh-138 mt16 text-dark full-width from global;
}

.signup-subtitle-sc {
  composes: flex-start text-23 lh-138 mt8 text-dark full-width from global;
  flex-wrap: wrap;
  align-items: center;
}

.icon-edit {
  composes: icon-edit text-blue pointer from global;
}

.broker-popular-tag {
  composes: text-12 font-medium br-4 pl8 pr8 pt4 pb4 lh-138 from global;
  color: var(--text-color-dark);
  background-color: #eefcf8;
}

.broker-preferred-tag {
  composes: text-12 font-medium br-4 p4 from global;
  color: var(--text-color-dark);
  background-color: var(--moderate-risk-bg);
}

.broker-logo {
  height: 18px;
  width: 18px;
}

@media (--screen-tabletOrLower) {
  .sub-title {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .back-btn:active {
    color: var(--color-blue);
  }
  .back-btn:hover {
    color: var(--text-color-dark);
  }
}

@media (--screen-mobile), (--screen-smaller) {
  .title {
    font-size: 18px;
  }

  .title-img {
    width: 100px;
  }

  .sub-title {
    font-size: 14px;
  }
}
