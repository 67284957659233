.breadcrumbs-container {
  composes: text-dark pt32 pl16 pr16 from global;
  max-width: 1140px;
  margin: 0 auto;
  padding-bottom: 0;
}

.icon {
  composes: text-20 from global;
}

.chevron {
  composes: pl4 pr4 from global;
}

.link-name {
  composes: text-15 text-dark from global;
  vertical-align: middle;
  cursor: not-allowed;
}

.clickable {
  cursor: none;
}

.clickable:hover {
  color: var(--color-blue);
  cursor: pointer;
}

.link:visited {
  color: unset;
}
