.footer-wrap {
  composes: full-width from global;
  border-top: 1px solid var(--color-border);
  background-color: var(--banner-bg);
}

.link-item {
  composes: text-13 p2 br-4 from global;
  margin-top: 0;
  color: var(--text-color-normal);

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-blue);
    transition: 0.3s;
  }
  &:hover {
    color: var(--color-blue);
  }
}

.copy-rights {
  composes: text-13 mb8 lh-169 from global;
}

.get-app-text {
  composes: text-13 mb16 lh-169 from global;
}

.submitted-btn {
  background-color: var(--color-white);
}

.footer-inner {
  composes: pt16 pb32 flex from global;
  flex-direction: row;
  justify-content: space-between;
}

.separator {
  border-top: solid 1px var(--color-border);
}

.footer-logo-placeholder {
  width: 120px;
  height: 37px;
}

.subscriber-text {
  composes: text-13 pt8 lh-169 from global;
}

.copy-rights-mail {
  composes: font-medium text-normal from global;
  &:hover {
    color: var(--color-blue);
  }
}

.smallcase-logo-text {
  composes: text-13 from global;
}

.d-flex {
  composes: flex from global;
}

.justify-content-between {
  justify-content: space-between;
}

.footer-container {
  composes: pt48 pb32 pr16 pl16 from global;
  display: block;
  max-width: 1140px;
  margin: 0 auto;
}

.smallcase-logo-full {
  height: 29px;
  width: 110px;
}

.risks-text {
  composes: text-12 text-normal lh-144 from global;
}

.product-links {
  composes: flex from global;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  padding-left: 64px;
}

.product-links-list {
  display: block;
  /* flex: 1 0 25%; */
  margin: 0;
  margin-bottom: 8px;
}

.product-links-item {
  padding-left: 2px;
  margin: 0;
  margin-bottom: 8px;
}

.address {
  composes: text-14 from global;
}

.contact-info {
  composes: pt24 from global;
}

.footer-social-icons {
  composes: flex mb16 from global;
  gap: 16px;
}

.footer-social-icon {
  composes: relative br-4 from global;
  color: var(--text-color-light);
  width: 24px;
  height: 24px;

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-blue);
    transition: 0.3s;
  }
  &:hover {
    color: var(--color-blue);
  }
}

.footer-social-icon::after {
  position: absolute;
  content: '';
  inset: 0;
  background-color: var(--hover-bg);
  transition: 0.3s;
  opacity: 0;
  border-radius: 50%;
  transition-timing-function: ease-in-out;
}

.footer-social-icon:hover::after {
  inset: -4px;
  opacity: 1;
  border-radius: 4px;
  color: unset;
}

.icon {
  composes: pl8 pr8 pt4 pb4 from global;
  font-size: 20px;
  color: var(--text-color-light);
}

.icon-red {
  color: var(--color-danger);
}

.other-info {
  composes: flex pl24 from global;
  flex-direction: column;
  color: var(--text-color-normal);
}

.license-image {
  composes: full-width mb0 from global;
  max-width: 120px;
  border-width: 2px;
}

.address-paragraph {
  composes: text-14 lh-169 from global;
}

.app-link {
  composes: flex pl24 from global;
  flex-direction: column;
}

.google-badge {
  width: 107.43px;
  height: 32px;
}
.apple-badge {
  width: 95.73px;
  height: 32px;
}

@media (max-width: 991px) {
  .footer-inner:first-child {
    display: block;
  }

  .footer-inner:last-child {
    flex-wrap: wrap;
  }

  .product-links {
    padding-left: 0;
    margin-top: 32px;
  }

  .address {
    flex: 0 0 50%;
  }

  .contact-info {
    flex: 0 0 50%;
    padding-left: 0;
    padding-top: 0;
  }

  .other-info {
    padding-left: 0;
    flex: 0 0 50%;
    margin-top: 24px;
  }

  .app-link {
    flex: 0 0 50%;
    padding-left: 0;
    margin-top: 16px;
  }

  .license-figure {
    display: inline-block;
    margin-right: 24px;
  }

  .license-figure:last-child {
    margin-right: 0;
  }

  .footer-inner {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .address {
    flex: 0 0 100%;
  }

  .product-links-list {
    flex: 0 0 100%;
  }

  .product-links-item {
    display: inline-block;
    padding-right: 16px;
  }

  .product-links-item:first-child {
    display: block;
    margin-top: 16px;
  }

  .contact-info {
    margin-top: 24px;
    flex: 0 0 100%;
  }

  .other-info {
    flex: 0 0 100%;
  }

  .app-link {
    margin-top: 16px;
    flex: 0 0 100%;
  }
  .risks-text {
    line-height: 1.69;
  }

  .subscriber-text {
    padding-top: 16px;
  }
}

@media (max-width: 360px) {
  .product-links-item {
    display: block;
    padding-right: 0;
  }
}
