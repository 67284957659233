.cover {
  composes: flex-center mt64 from global;
  img {
    width: 100%;
    max-width: 395px;
  }
}

@media (--screen-tabletOrLower) {
  .cover {
    margin-top: 32px;
    min-height: 70vh;
  }
}
