.step-header {
  composes: flex flex-column text-left mt24 from global;
  align-items: flex-start;
}

.broker-steps-container {
  composes: flex mt16 from global;
}

.broker-step {
  composes: flex text-center flex-column from global;
  align-items: center;
  flex: 1;
}

.broker-steps-heading {
  color: var(--text-color-dark);
}

.broker-step-icon {
  composes: text-24 from global;
}

.broker-step-text {
  composes: text-12 from global;
  color: var(--text-color-light);
  max-width: 90%;
}

.button-container {
  composes: full-width from global;
}

.broker-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.another-broker-cta {
  composes: mt12 mb12 from global;
  margin-left: auto;
  margin-right: auto;
  color: var(--color-blue);
}
