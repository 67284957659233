.topRight {
  composes: absolute from global;
  top: 0;
  right: 0;
}

.brokerImage {
  composes: absolute img-16 flex-center from global;
  bottom: 0;
  right: -8px;
  border: 0.5px solid var(--color-border);
  border-radius: 50%;
  background-color: var(--color-white);
}
