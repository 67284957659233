.container {
  composes: flex-center full-height from global;
  flex-direction: column;
}

.animation {
  width: 250px;
  height: 250px;
}

.inner-container {
  composes: full-width flex p16 from global;
  flex-direction: column;
  align-items: center;
  height: 50%;
}

.animation-container {
  composes: full-height from global;
  margin: 0 auto;
  max-width: 250px;
  flex: 0.7;
}
