.close-btn-container {
  composes: absolute from global;
  top: 20px;
  right: 20px;
}

.close-btn {
  composes: icon-bg-hover text-18 text-light from global;
}

.container {
  composes: flex-center full-height from global;
  flex-direction: column;
}

.inner-container {
  composes: flex p16 from global;
  flex-direction: column;
  align-items: center;
  height: 50%;
  width: 100%;
}

.animation-container {
  composes: full-height from global;
  margin: 0 auto;
  max-width: 250px;
  flex: 0.7;
}

.animation {
  width: 250px;
  height: 250px;
}

.progress-container {
  composes: mt8 from global;
  width: 80%;
}

.title-container {
  min-height: 88px;
}

.too-long-btn-container {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}
