.illustration {
  display: block;
}

.toast-text {
  word-break: break-word;
}

.input-with-returns-explainer-container {
  composes: flex from global;
  gap: 24px;
}

.returns-explainer-container {
  max-width: 384px;
  min-width: 384px;
  overflow: scroll;
  max-height: 385px;
}

.input-step-container {
  max-width: 384px;

  @media (--screen-tabletOrLower) {
    max-width: unset;
  }
}
