.alert-banner-container {
  height: 64px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgba(249, 250, 251, 0.95);
  overflow: hidden;
  box-shadow: 0 -1px 8px 0 #00000014;
  border: 1px solid var(--color-border);
  border-bottom: none;
}

.alert-container {
  composes: flex pl16 pr16 from global;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.alert-tap-area {
  composes: flex full-width p0 from global;
  align-items: center;
}

.shield-icon {
  flex: 1;
}

.alert-title {
  composes: font-medium from global;
}

.alert-description {
  composes: text-12 font-normal text-light from global;
}

.icon-container {
  composes: flex from global;
  align-items: center;
}

@media (--screen-mobile), (--screen-smaller) {
  .alert-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
