.inner-container {
  composes: relative from global;
  border-radius: 50px;
  margin: auto;
  border: 2px solid var(--color-success);
  width: 86px;
  height: 86px;
}
.icon-container {
  composes: absolute m-auto from global;
  top: -10px;
  left: 0;
  right: 0;
}

.sc-image {
  composes: br-4 from global;
  margin: 20px;
}
