.hamburger {
  /* composes: pl12 from global; */
  /*
    Since we are using reset-button-style from global it sets margin to 0
    so to override it the margin right is hardcoded here. We can't give
    mr12 from global because spacers are imported before utility so have
    less specificity. That's why we have override the margin like this
  */
  margin-right: 12px;
}

.faq-button {
  composes: flex pt4 pb4 pl8 pr8 from global;
  border-radius: 16px;
  background-color: var(--background-grey);
  align-items: center;
  color: var(--text-color-dark);
}
