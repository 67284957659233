.card {
  composes: full-width flex items-center text-left br-8 pointer from global;
  gap: 16px;
  border: 1px solid var(--color-border);
}

.pd-normal {
  composes: pb12 pt12 pr16 pl16 from global;
}

.pd-lg {
  composes: p16 from global;
}

.br-dashed {
  border-style: dashed;
}

.flex-full {
  composes: flex-1 from global;
  min-width: 0;
}

.shimmer-title {
  composes: shine br-4 from global;
  max-width: 80px;
  height: 20px;
  margin-bottom: 2px;
}

.shimmer-title-full {
  max-width: 176px;
}

.shimmer-value {
  composes: shine full-width br-4 from global;
  max-width: 175px;
  height: 16px;
}

.shimmer-icon {
  composes: shine from global;
  width: 24px;
  height: 24px;
}

.shimmer-icon-round {
  border-radius: 50%;
}

.shimmer-width-full {
  composes: full-width from global;
  max-width: 100%;
}
