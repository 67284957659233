.title-container {
  composes: mt8 flex-between from global;
  align-items: flex-end;
}

.title {
  composes: font-medium text-dark from global;
  line-height: 1.29;
  font-size: 23px;
}

.title-img {
  width: 135px;
  margin-right: -8px;
  margin-top: -8px;
}

.form-container-outer {
  composes: relative flex full-height full-width from global;
  vertical-align: top;
  flex-grow: 1;
}

.mt-0 {
  margin-top: 0;
}

.form-container-inner {
  composes: full-height full-width inline-flex from global;
  transition: 0.6s cubic-bezier(0.69, 0, 0.24, 1.01);
  flex-wrap: nowrap;
  max-width: unset;
  flex-grow: 1;
}

/* .signup-title {
  composes: font-medium text-22 from global;
  line-height: 1;
  color: var(--text-color-dark);
} */

.signup-subtitle {
  composes: text-23 lh-138 mt8 mb24 text-dark from global;
}

.step-container form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.form-fields-wrapper {
  composes: flex-column from global;
  align-items: flex-start;
  justify-content: flex-end;

  /* this is required for scrolling purpose */
  height: 1px;
  flex: 1 1 auto;
}

.form-accordion {
  justify-content: flex-start;
}

.form-fields-wrapper.form-disabled {
  opacity: 0.4;
  pointer-events: none;
}

.form-header-text {
  position: relative;
}

.form-header-text p:last-child {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 13px;
}

.email-form {
  composes: mt24 from global;
}

.icon-help {
  composes: icon-help text-light pointer text-13 p4 from global;
  border-radius: 4px;
  opacity: 0.5;
}

.form-label {
  composes: mb8 line-height-one from global;
  display: inline-block;
  color: var(--text-color-dark);
  composes: font-medium text-14 from global;
}

.form-field-wrapper {
  width: 100%;
  margin-left: unset;
}

.form-input {
  width: 100%;
  composes: ipt text-16 mb4 from global;
  padding: 18px 16px;
  letter-spacing: 0.4px;
  height: 52px;
}

.form-input:autofill,
.form-input:autofill:hover,
.form-input:autofill:focus,
.form-input:autofill:active,
.form-input:-webkit-autofill,
.form-input:-webkit-autofill:hover,
.form-input:-webkit-autofill:focus,
.form-input:-webkit-autofill:active {
  box-shadow: 0 0 0 32px var(--color-white) inset;
  -webkit-box-shadow: 0 0 0 32px var(--color-white) inset;
}

.form-input:disabled {
  box-shadow: 0 0 0 32px #f4f4f5 inset !important;
  -webkit-box-shadow: 0 0 0 32px #f4f4f5 inset !important;
}

input.form-input::placeholder {
  color: var(--text-color-light);
}

.phone-input-container {
  position: relative;
}

.verified-animation {
  position: absolute;
  top: 25%;
  right: 10px;
  width: 26px;
  height: 26px;
  border-radius: 10px;
}

.inpur-error {
  border: 1px solid rgba(255, 89, 66, 0.6);
}

.edit-btn {
  composes: link ml4 from global;
  font-weight: 600;
}

.signup-page-btn-big {
  composes: btn btn-lg font-regular btn-primary-blue mt8 from global;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: unset;
  z-index: 1;
}

.signup-page-btn-big:disabled {
  /*
    Default button disabled background is set to rgba(31,122,224, 0.45)
    Because of the alpha part set to 0.45, anything animating even if going below the button seems like going up
  */
  background-color: #a0c3f2;
}

.form-footer {
  composes: absolute full-width from global;
  height: 24px;
  bottom: 0;
  background-color: var(--color-white);
  transform: translateY(24px);
}

.signup-page-btn-small {
  composes: btn btn-lg btn-primary-blue from global;
  height: 52px;
}

.signup-page-back-btn {
  composes: btn btn-lg btn-secondary-blue from global;
  height: 52px;
}

.btn-width-auto {
  width: calc(50% - 8px);
}

.step-container {
  composes: flex-column full-width from global;
  vertical-align: top;
  visibility: visible;
  transition: 0.6s cubic-bezier(0.69, 0, 0.24, 1.01);
}

.hidden {
  visibility: hidden;
}

.success-icon {
  composes: img-40 from global;
}

.success-msg {
  composes: text-14 mb32 ml16 from global;
  display: inline-block;
  width: 294px;
  height: 42px;
  vertical-align: middle;
  line-height: 1.47;
}

.broker-bold {
  composes: font-medium from global;
}

.input-error-container {
  composes: text-12 font-medium mb8 from global;
  color: var(--color-danger);
  height: 12px;
}

.newsletter-check {
  composes: pt8 mb4 from global;
  font-size: 24px;
  user-select: none;
  cursor: pointer;
}

.newsletter-check::before {
  vertical-align: middle;
}

.newsletter-label {
  composes: font-medium text-14 ml12 from global;
  color: var(--text-color-dark);
  vertical-align: middle;
}

.newsletter-msg {
  line-height: 1.47;
  margin-left: 36px;
  margin-bottom: 54px;
}

.discover-card {
  composes: ml56 p16 from global;
  width: 294px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid var(--color-border);
}

.discover-card-icon {
  composes: img-32 from global;
  display: inline-block;
  vertical-align: top;
}

.discover-card-header {
  composes: font-medium text-14 from global;
  color: var(--text-color-dark);
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 8px;
}

.discover-card-text {
  composes: lh-138 mb16 from global;
  width: 262px;
}

.divider {
  width: 262px;
  height: 1px;
  margin: 0 auto 8px;
  opacity: 0.2;
  background-color: var(--color-border);
}

.broker-search-wrapper {
  composes: mb16 from global;
}

.search-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 42px;
  padding: 0 12px;
  border-radius: 4px;
  border: 1px solid var(--color-border);
}

.toggle {
  composes: text-14 text-normal pointer from global;
  width: 100%;
  user-select: none;
  outline: none;
  border: none;
  line-height: 1;
}

.toggle:hover {
  color: var(--text-color-dark);
}

.toggle.active,
.toggle.active:hover {
  border-bottom: 1px solid var(--color-blue);
}

.dropdown-button {
  position: absolute;
  right: 12px;
  top: 12px;
}

.selected-text {
  composes: font-regular ellipsis from global;
  color: var(--text-color-light);
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  width: 149px;
  line-height: 42px;
}

.selected-text-broker {
  composes: font-medium ellipsis from global;
  color: var(--color-blue);
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  width: 149px;
  line-height: 42px;
}

.selected-input {
  border: 1px solid var(--color-blue);
  border-radius: 4px;
}

.sub-title {
  composes: text-16 mt16 text-dark from global;
  line-height: 1;
  margin-bottom: 18px;
}

.form {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.phase2 form {
  position: relative;
}

.phase2 .sub-title {
  margin-bottom: 8px;
}

.backdrop-left {
  position: absolute;
  left: -24px;
  top: 100px;
  z-index: -1;
  user-select: none;
}

.backdrop-right {
  position: absolute;
  left: 260px;
  top: -80px;
  z-index: -1;
  user-select: none;
}

.external-link-icon {
  composes: mr8 from global;
  height: 15px;
}

/* .footer-btn-modal {
  transition: all 0.2s ease-in-out;
} */

.footer-btn-modal.box-shadow {
  margin-left: -24px;
  margin-right: -24px;
  background-color: var(--color-white);
  z-index: 9999;
  padding: 24px 24px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
}

.step0,
.step1 {
  transform: translateY(100%);
}

.step2 {
  animation-name: slideUp;
  animation-duration: 0.4s;
}

.step2 .step1-hidden {
  visibility: hidden;
}

.step3 .step1-hidden {
  visibility: hidden;
}

.display-name {
  composes: inline-block ellipsis from global;
  max-width: 265px;
}

.icon-edit {
  composes: icon-edit text-blue pointer from global;
}

.mt24 {
  margin-top: 24px;
}

@media (--screen-tabletOrLower) {
  .form-container-outer {
    width: 100%;
  }

  .discover-card-text {
    width: auto;
  }

  .form-field-wrapper {
    flex-basis: 50%;
    margin-left: 0;
    margin-right: 0;
  }

  .step-container.step2 .signup-page-btn-small,
  .step-container.step2 .signup-page-back-btn {
    margin-top: 8px;
  }

  .sub-title {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

@media (--screen-mobile), (--screen-smaller) {
  .step-container {
    min-width: unset;
  }

  .discover-card {
    width: calc(100vw - 88px);
  }

  .success-msg {
    width: calc(100vw - 98px);
    height: auto;
  }

  .title {
    font-size: 18px;
  }

  .title-img {
    width: 100px;
  }

  .sub-title {
    font-size: 14px;
  }

  .display-name {
    max-width: 240px;
  }
}

@media (--screen-smaller) {
  .display-name {
    max-width: 180px;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
