.error-text {
  composes: text-12 from global;
  color: var(--color-danger);
}

.step-header {
  composes: flex text-left mt24 from global;
  align-items: flex-start;
  line-height: 157%;
}

.step-desc {
  composes: text-14 mb24 mt4 from global;
  align-items: flex-start;
  line-height: 144%;
}

.sso-container {
  composes: flex-column flex-center mb32 from global;
  width: 100%;
  gap: 24px;
  align-items: center;
}

.sso-btns-container {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.sso-button {
  height: 42px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.divider {
  composes: flex-center from global;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  color: var(--text-color-light);
}

.sso-email-container {
  composes: flex-center flex-column full-width from global;
  gap: 8px;
}

.sso-input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d4d6d8;
  padding: 13px 12px 14px 12px;
  font-size: 14px;
}

input::placeholder {
  color: var(--text-color-light);
}

.terms {
  composes: text-12 text-left mt8 mb24 lh-144 from global;
  color: var(--text-color-light);
}

@media (--screen-tabletOrLower) {
  .sso-container {
    margin-bottom: 16px;
  }
}
