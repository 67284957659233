.container {
  composes: flex flex-column full-height from global;
  justify-content: space-between;
}

.holding-row {
  composes: flex text-left mb16 relative from global;
  height: 50px;
  align-items: center;
}

.marker {
  composes: relative flex full-height from global;
  /* width: 10%; */
  align-items: end;
}
.marker::after {
  content: '';
  position: absolute;
  left: 44%;
  border-right: 1px dashed var(--color-border);
  height: 90px;
}

.circle {
  background-color: var(--color-white);
  width: 21px;
  height: 21px;
  border-radius: 100%;
  z-index: 1;
}
.circle i {
  font-size: 17px;
}
.stock-row-end {
  composes: flex full-height from global;
  flex-direction: column;
  justify-content: end;
}

.footer {
  composes: full-width mb12 from global;

  @media (--screen-mobile) {
    margin-bottom: 24px;
  }
}
