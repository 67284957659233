.cta-container {
  composes: p16 relative full-width font-regular mt8 pointer text-left from global;
  background-color: var(--color-ghost-white);
}

.cta-image {
  composes: absolute from global;

  bottom: 0;
  right: -12px;
  height: 71px;
  width: 102px;
}

.google-badge {
  width: 107.43px;
  height: 32px;
}
.apple-badge {
  width: 95.73px;
  height: 32px;
}
