.step-header {
  composes: flex flex-column text-left mt24 from global;
  align-items: flex-start;
}

.button-container {
  composes: full-width mt24 mb24 from global;
}

.broker-button {
  composes: flex btn btn-md btn-fw btn-secondary-blue btn-mw-unset full-width from global;
  align-items: center;
  justify-content: center;
}

.image-container {
  composes: flex mt24 from global;
  justify-content: space-between;
  align-items: flex-start;
}

.illustration {
  display: block;
}

@media (--screen-desktop), (--screen-tablet) {
  .illustration {
    width: 144px;
  }
  .illustration-small {
    width: 60px;
  }
}

@media (--screen-mobile) {
  .illustration {
    width: 100px;
  }
}

.logo {
  width: 18px;
  height: 18px;
}

.cta-text {
  composes: flex from global;
  justify-content: center;
}

.logo-switch-container {
  composes: flex pl8 pr8 br4 from global;
  gap: 8px;
  background-color: var(--background-very-light-blue);
  height: 33px;
  width: fit-content;
}

.logo-container {
  composes: flex from global;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 100%;
  white-space: nowrap;
}

.body-text {
  composes: mt16 lh-169 from global;
  color: var(--text-color-light);
}

.fast-rewind-icon {
  composes: block m-auto from global;
}

.broker-display-text {
  composes: block ml8 flex-center ellipsis font-medium from global;
  color: var(--text-color-normal);
  max-width: 130px;
}
