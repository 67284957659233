.banner-container {
  height: 64px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--banner-bg);
  overflow: hidden;
  box-shadow: 0 -1px 8px 0 #00000014;
  border: 1px solid var(--color-border);
  border-bottom: none;
}

.inner-container {
  composes: flex pl16 pr16 from global;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.body {
  composes: flex full-width p0 from global;
  align-items: center;
}

.icon-container {
  composes: flex-center from global;
}

.loader-icon {
  flex: 1;
  height: 20px;
  width: 20px;
}

.animate-spin {
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
