.modal-overlay-div {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.95);
}

.modal-overlay-dark {
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content-div {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  overflow: auto;
  text-align: center;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  animation: fade-in 300ms ease-out;
}

.unset-top {
  top: unset;
}

.modal-content-div::after {
  vertical-align: middle;
  display: inline-block;
  height: 100%;
  margin-left: -0.05em;
  content: '';
}

.modal-dialog-div {
  position: relative;
  outline: 0;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  max-width: auto;
  cursor: default;
  background-color: white;
  border-radius: 5px;
  transition: height 0.5s ease-out, width 0.5s ease-out;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.05);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.header {
  composes: flex-apart-top from global;
  padding: 16px 24px;
  background-color: #f9fafb;
  border-radius: 4px 4px 0 0;
}

.header-text-container {
  /* this being a flex child, min width is needed for children having ellipsis style */
  min-width: 0;
}

.header-title {
  composes: text-dark text-16 ellipsis from global;
}

.header-subtitle {
  composes: text-light text-13 ellipsis from global;
  padding-top: 2px;
}

.content {
  overflow: auto;
}

@media (--screen-tabletOrLower) {
  .modal-content-div {
    padding: 0;
  }

  .modal-content-div::after {
    vertical-align: bottom;
  }

  .header {
    padding: 16px;
  }
}
