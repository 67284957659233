.container {
  composes: text-left pt24 pb24 from global;
}

.heading {
  font-size: 21px;
}

.input-container {
  composes: mt24 from global;
}

.input-field {
  composes: ipt ipt-lg mt4 from global;
  flex-grow: 1;
  /*
        ipt-lg has width of 294px and modal's width is 380px, so width needs to be overridden
      */
  width: 100%;
}

.input-field::placeholder {
  color: var(--text-color-light);
}

.error-text {
  composes: text-12 mt8 mb8 from global;
  color: var(--color-danger);
}

.continue-button {
  margin-top: 28px;
}
