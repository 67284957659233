.form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 500px;
}

.input {
  padding: 10px;
  width: 100%;
}
