/* default variant */
.error-state-default {
  composes: flex from global;
  align-items: center;
}

.error-text-default {
  composes: ml48 lh-138 from global;
  width: 360px;
}

.error-image-default {
  height: 337px;
  width: 675px;
}

/* compact variant */
.error-state-compact {
  composes: flex text-center from global;
  flex-direction: column;
  align-items: center;
}

.error-image-compact {
  composes: mb24 from global;
  max-width: 675px;
  padding: 0 16px;
}

.error-text-compact {
  composes: lh-138 from global;
  padding: 0 16px;
}

@media (--screen-tabletOrLower) {
  .error-state-default {
    display: block;
  }

  .error-image-default {
    height: auto;
    margin-bottom: 24px;
  }
  .error-text-default {
    margin: 0;
  }

  .error-image-default,
  .error-text-default {
    width: 100%;
    text-align: center;
    padding: 0 16px;
  }

  /* compact variant */
  .error-image-compact,
  .error-text-compact {
    width: 100%;
  }
}
