.image-container {
  composes: flex mt24 from global;
  justify-content: space-between;
  align-items: flex-start;
}

.illustration {
  display: block;
  width: 72px;
  @media (--screen-desktop), (--screen-tablet) (--screen-mobile) {
    width: 84px;
  }
}
