.link {
  composes: text-14 pointer from global;
  vertical-align: baseline;
  margin-left: 32px;
  color: var(--text-color-light);
  white-space: nowrap;
  border-bottom: 1px solid transparent;
  padding: 20px 0;
}

.link:hover {
  color: var(--text-color-dark);
}

.link-active {
  color: var(--text-color-dark);
  border-bottom: 1px solid var(--text-color-dark);
}

@media (--screen-tabletOrLower) {
  .link {
    margin-left: 20px;
  }
}
