.primary-announcement-container {
  composes: full-width from global;
  /* 1px for border of the header */
  margin-top: calc(var(--header-height) + 1px);
  /* 
        .grid-container marginTop(88px) - header-height(56) = space b/w header and home components
        As grid-container already applies 88px, we add -v header-heigh to make the space same as 
        what was present before the announcement
       */
  margin-bottom: calc(var(--header-height) * -1);
}
