.amc-logo-container {
  composes: flex-center br-4 from global;
  border: 0.5px solid var(--color-border);
  overflow: hidden;
}

.fallback-image {
  background-image: url('https://assets.smallcase.com/images/mutualfunds/amc/160/fallback.png');
  background-position: center;
  background-size: contain;
}
