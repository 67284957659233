.navbar-collapse-hide {
  composes: flex desktop-hidden from global;
  position: absolute;
  padding-top: 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
  top: 56px;
  right: 0;
  left: 0;
  max-height: 0;
  transition: max-height 0.3s ease-out, border-bottom 0.3s ease-out,
    padding-top 0.3s ease-out;
  overflow: hidden;
  background: var(--color-white);
  border-bottom: 0 solid var(--color-border);
}

.sheet-container {
  composes: tablet-container pt8 flex desktop-hidden from global;
  flex-direction: column;
  width: 100%;
  list-style: none;
}

.navbar-collapse-show {
  max-height: fit-content;
  transition: max-height 0.3s ease-out, border-bottom 0.3s ease-out,
    padding-top 0.3s ease-out;
  border-bottom: 1px solid var(--color-border);
  border-top: 1px solid var(--color-border);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.dropdown-link {
  composes: flex text-14 text-normal from global;
  vertical-align: baseline;
  white-space: nowrap;
}

.shiny-badge {
  composes: ml8 from global;
  /* Overrides default font size of xs */
  font-size: 10px;
}
