.container {
  composes: z-order-one flex from global;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

.navbar {
  composes: z-order-one flex from global;
  height: var(--header-height);
  background: var(--color-white);
  border-bottom: 1px solid transparent;
  justify-content: center;
  box-shadow: 0 1px 0 0 var(--color-border);
}

@media (--screen-tabletOrLower) {
  .navbar-dm-web {
    border-radius: 12px 12px 0 0;
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(calc(-1 * var(--header-height)));
  }
  100% {
    transform: translateY(0);
  }
}

.navbar-main-container {
  composes: pl16 pr16 flex tablet-container from global;
  justify-content: space-between;
  width: 1152px;
  max-width: 100%;
}

.navbar-items-container {
  composes: flex full-width from global;
  align-items: center;
  flex-grow: 1;
  height: 56px;
}

.left-items-container {
  composes: flex-start from global;
}

.right-items-container {
  justify-content: flex-end;
}
