.outer-container {
  composes: p24 flex flex-column full-width flex-start from global;
}

.container-2 {
  composes: pt24 pb24 mob-hidden from global;
  align-self: flex-start;
}

@media (--screen-tabletOrLower) {
  .outer-container {
    padding: 16px;
  }
}
