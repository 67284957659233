.step-header {
  composes: flex text-left mt24 from global;
  align-items: flex-start;
}

.edit-button {
  composes: relative ml8 from global;
}

.edit-icon {
  composes: icon-edit text-blue pointer from global;
}

.otp-container {
  composes: mt24 from global;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
}

.otp-error {
  composes: text-12 pt24 from global;
  color: var(--color-danger);
}

.resend-button {
  composes: relative from global;
  padding-top: 24px !important;
  padding-bottom: 38px !important;
}

.edit-button {
  composes: relative from global;
  margin-left: 8px !important;
}
