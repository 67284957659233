.container {
  composes: ml32 from global;
}

.login-button {
  composes: btn btn-secondary-blue from global;
  min-width: unset;
}

@media (--screen-tabletOrLower) {
  .container {
    margin-left: 4px;
  }
}
