.inputs-container {
  composes: flex flex-column full-height from global;
  justify-content: space-between;
}

.error-text {
  composes: text-12 mt4 from global;
  color: var(--color-danger);
}

.error-gray {
  composes: text-12 mt4 from global;
  color: var(--text-color-light);
}

.number-input {
  composes: br-4 ipt ipt-lg block from global;
  /* defined here instead of from global as it is attached to country code selector */
  flex-grow: 1;
  /* ipt-lg is overriding the width to 294px , adding width:100% !important to override that */
  width: 100% !important;
}

.number-input::placeholder {
  color: var(--text-color-light);
}

.number-input-error {
  border: 1px solid var(--color-danger);
}

.error-text {
  composes: text-12 text-left mb12 pt4 from global;
  color: var(--color-danger);
}

.error-text.visible {
  display: block;
}

.info {
  composes: flex items-center p8 br-4 from global;
  background-color: rgba(var(--color-green-rgb), 8%);
}
.pan-edit-info {
  composes: flex p8 mb8 br-4 from global;
  background-color: #f9fafc;
}
.divider {
  composes: mt16 from global;
  height: 1px;
  margin-left: -24px;
  margin-right: -24px;
  background-color: var(--color-border);
  max-width: unset;

  @media (--screen-mobile) {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.form-label {
  composes: inline-block lh-144 from global;
  margin-bottom: 2px;
}

.info-text {
  composes: text-left ml8 text-12 lh-138 from global;
}

.header-help-btn {
  composes: flex pt4 pb4 pl8 pr8 text-12 from global;
  background-color: rgba(129, 135, 140, 0.05);
  border-radius: 14px;
  color: var(--text-color-light);
  div {
    margin-bottom: 2px;
  }
}

.alert-icon-image {
  align-self: flex-start;
}
