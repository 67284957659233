.cta-container {
  composes: p16 relative full-width font-regular pointer text-left from global;
  background-color: var(--color-ghost-white);
  margin-top: -8px;

  &:hover > p {
    color: var(--link-hover);
  }
}

.cta-image {
  composes: absolute from global;
  bottom: 0;
  right: -12px;
  width: 75px;
  height: 51px;
}
