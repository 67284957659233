.footer-wrapper {
  composes: pt16 pb16 ml16 mr16 flex from global;
  align-items: center;
}

.sc-logo {
  composes: mr8 from global;
  filter: invert(0.5) brightness(1.3);
  height: 12px;
  width: 12px;
}
