.close-btn {
  composes: icon-bg-hover text-18 text-light absolute from global;
  right: 24px;
  top: 24px;
}

.content-container {
  composes: pl24 pr24 pb24 from global;
}

.heading {
  composes: mt32 text-20 text-dark font-medium from global;
}

@media (--screen-tabletOrLower) {
  .close-btn {
    right: 16px;
    top: 16px;
  }

  .content-container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .heading {
    margin-top: 24px;
  }
}
