.shiny-badge {
  /* Overrides xs badge font-size */
  font-size: 10px;
}

.divider {
  composes: mt8 mb8 from global;
  height: 1px;
  background-color: var(--color-border);
}

/* TODO: this should be cleaned at source. i.e OverflowMenu.jsx */
.overflow-menu > li,
.overflow-menu > div {
  white-space: unset;
  line-height: unset;
  outline: unset;
  user-select: unset;
  cursor: unset;
  position: unset;
  padding: unset;
  margin-bottom: unset;
}

.overflow-menu > a {
  width: unset;
  line-height: unset;
  padding: unset;
  display: unset;
  color: unset;
}

.overflow-menu > li > a {
  width: unset;
  line-height: unset;
  margin: unset;
  padding: unset;
  color: unset;
}

.overflow-menu > li:hover,
.overflow-menu > div:hover,
.overflow-menu > a:hover {
  background-color: unset;
}

/* Reset baked in style */
.overflow-menu-container > div {
  position: unset;
}

.overflow-menu {
  composes: absolute pt8 pl0 pr0 br-4 from global;
  width: 280px;
  right: 0;
  top: 48px;
  color: var(--text-color-normal);
  overflow: hidden;
}

.menu-button {
  composes: reset-button-style ml32 flex full-height items-center text-14 pointer from global;
  vertical-align: baseline;
  color: var(--text-color-light);
  white-space: nowrap;
  border-bottom: 1px solid transparent;
}

.menu-button:hover {
  color: var(--text-color-dark);
}
