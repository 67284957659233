.step-row {
  /** For aligning text with icon*/
  margin-top: 2px;
}
.heading-container {
  composes: ml24 mb16 from global;
}

.usp-nudge {
  composes: p12 pr24 m24 br-8 flex from global;
  background-color: rgba(255, 246, 217, 1);
}
.usp-nudge-text {
  composes: mt4 ml4 text-left from global;
  font-size: 13px;
  line-height: 16px;
}
.checkbox-container {
  composes: flex ml24 mr24 mt16 from global;
  justify-content: flex-start;
}
/* Custom checkbox label has baked in display block property
which we need to override here to avoid overflow.
TODO: Investigate this and change in the CustomCheckbox component itself.
*/
.checkbox-inner-content {
  white-space: unset !important;
  display: flex !important;
  align-items: flex-start;
}

.dismiss-btn {
  composes: text-12 pl0 pointer font-medium from global;
  color: var(--color-blue);
}

.consent-check {
  composes: text-left pl4 text-12 from global;
  /** For aligning text with checkbox*/
  margin-bottom: 2px;
}

.header {
  composes: flex mb24 from global;
  height: 170px;
  justify-content: flex-end;
  background-image: url('../../../../../assets/svg/track/holdingsImport/get-started-header.svg');
  background-size: cover;
}

.close-btn {
  composes: m24 from global;
}
.divider {
  composes: mt16 from global;
  height: 1px;
  background-color: var(--color-border);
}

.get-started-btn {
  composes: btn btn-primary-blue btn-lg btn-fw btn-mw-unset from global;
}

.cta-container {
  composes: ml24 mr24 mt16 mb16 from global;
}

@media (--screen-tabletOrLower) {
  .heading-container {
    margin-left: 16px;
  }

  .usp-nudge {
    margin: 16px;
  }

  .checkbox-container {
    margin-left: 16px;
  }

  .cta-container {
    margin-left: 16px;
    margin-right: 16px;
  }
}
