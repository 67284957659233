.navbar-toggler {
  composes: reset-button-style pt8 pb8 from global;
  display: inline;
  cursor: pointer;
}
.bar1,
.bar2,
.bar3 {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: #a8adb0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.bar2,
.bar3 {
  composes: mt4 from global;
}

.toggle .bar1 {
  transform: rotate(45deg) translate(3px, 7px);
}

.toggle .bar2 {
  opacity: 0;
}

.toggle .bar3 {
  transform: rotate(-45deg) translate(2px, -5px);
}
