.card {
  composes: p16 br-8 mb16 text-left from global;
  border: 1px solid var(--color-border);
}

.divider {
  composes: mb12 from global;
  height: 1px;
  background-color: var(--color-border);
  border: none;
}

.card-list-item {
  composes: flex from global;
  gap: 16px;
}

.icon {
  composes: flex-center img-24 from global;
  border-radius: 50%;
  border: 1px solid var(--color-border);
  flex-shrink: 0;
}

.shimmer-heading {
  composes: shine br-4 from global;
  width: 82px;
  height: 16px;
}

.shimmer-item-img {
  composes: shine from global;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.shimmer-item-title {
  composes: shine br-4 mb4 from global;
  width: 80px;
  height: 20px;
}

.shimmer-item-value {
  composes: shine full-width br-4 from global;
  max-width: 216px;
  height: 20px;
}

.shimmer-item-badge {
  composes: shine br-4 mt8 from global;
  width: 103px;
  height: 16px;
}

.shimmer-copy-text {
  composes: shine br-4 from global;
  height: 14px;
}

.shimmer-width-half {
  composes: mt4 from global;
  width: 50%;
}
