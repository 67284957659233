.step-header {
  composes: flex text-left mt24 from global;
  align-items: flex-start;
}

.input-container {
  composes: mt24 from global;
}

.input-field {
  composes: ipt ipt-lg mt4 from global;
  flex-grow: 1;
  /*
        ipt-lg has width of 294px and modal's width is 380px, so width needs to be overridden
      */
  width: 100%;
}

.input-field::placeholder {
  color: var(--text-color-light);
}

.error-text {
  composes: text-12 mt4 from global;
  color: var(--color-danger);
}

/* Adding negative margin to error message because phone number input has baked in bottom margin */
.phone-number-error {
  margin-top: -10px;
}

.terms {
  composes: text-12 text-left mt24 mb8 lh-144 from global;
  color: var(--text-color-light);
}

.checkbox-container {
  composes: flex from global;
  justify-content: flex-start;
  align-items: center;
}

/* Custom checkbox label has baked in display block property
which we need to override here to avoid overflow.
TODO: Investigate this and change in the CustomCheckbox component itself.
*/
.checkbox-inner-content {
  white-space: unset !important;
  display: flex !important;
  align-items: center;
}
