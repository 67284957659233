.modal {
  composes: text-left from global;
  overflow-x: hidden;
  min-height: auto;
  height: 100%;
  max-height: 70%;

  @media (--screen-desktop) {
    height: 584px;
  }
}
