.sc-image-container {
  composes: flex-center relative mt8 mb24 from global;
}

.transaction-img {
  composes: absolute from global;
  bottom: -26px;
  width: 40px;
}

.market-reopening-timer {
  composes: flex-start p12 mb24 br-4 mt16 from global;
  border: 1px solid var(--color-border);
}

.header {
  composes: flex mt16 from global;
  justify-content: flex-end;
}
